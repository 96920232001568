<template>
  <div>
    <WsOrder
      :orderLayerFields="[]"
      :getUrl="_getUrl"
      :params="_params"
      :patchUrl="_patchUrl"
    ></WsOrder>
  </div>
</template>

<script>
export default {
  computed: {
    _getUrl() {
      return `/ws_class/order`;
    },
    _patchUrl() {
      return `/ws_class/order`;
    },
    _params() {
      let _params = {
        target: this._modelName,
        getall: 1,
      };
      return _params;
    },
    _orderable() {
      return this.$route.meta.orderable;
    },
    _modelName() {
      return this.$route.meta.modelName;
    },
  },
};
</script>